<template>
  <div class="users-list">
    <main-app-bar>
      <template v-slot:title> Users </template>
      <template v-slot:actions>
        <div class="app-bar--bottom-actions nav-pills">
          <v-btn
            depressed
            :to="{ name: 'users.all' }"
            data-testid="all-users-button"
          >
            All Users
          </v-btn>
          <v-btn
            depressed
            :to="{ name: 'users.previous' }"
            data-testid="previous-users-button"
          >
            Previous BBR Girls
          </v-btn>
          <v-btn
            depressed
            :to="{ name: 'users.flagged' }"
            data-testid="flagged-users-button"
          >
            Flagged Users
          </v-btn>
          <!--          <v-btn depressed :to="{ name: 'users.social' }">-->
          <!--            Social Media Consent-->
          <!--          </v-btn>-->
        </div>
        <v-btn
          class="primary bg-primary-gradient ml-auto"
          depressed
          @click="exportUsers"
          data-testid="export-users-button"
        >
          Export Users
          <v-icon class="ml-3" small>{{ icons.export }}</v-icon>
        </v-btn>
      </template>
    </main-app-bar>

    <div class="mt-5 px-10 pb-6">
      <v-row>
        <v-col cols="12">
          <div class="form-container">
            <div class="search-form">
              <v-row no-gutter>
                <v-spacer />

                <v-col cols="5" class="d-flex align-center">
                  <v-text-field
                    class="mr-2"
                    data-testid="user-search-input"
                    label="Search Users"
                    v-model="searchKey"
                    @input="searchChange"
                    hide-details
                    clearable
                    rounded
                    solo
                    flat
                  />

                  <v-btn
                    color="primary"
                    @click="openFilter = true"
                    dark
                    text
                    data-testid="filter-users-button"
                  >
                    <v-icon>{{ icons.filter }}</v-icon>
                    <span class="ml-3"> Filter </span>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>

        <v-col v-if="openFilter" cols="12" class="mb-5">
          <v-card outlined>
            <v-card-text>
              <v-row dense align="center">
                <v-col sm="2" cols="12">
                  <v-menu
                    v-model="joinDateMenu"
                    :close-on-content-click="false"
                    min-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="filter.joinDate"
                        v-on="on"
                        label="Join Date"
                        clearable
                        hide-details
                        outlined
                        readonly
                        flat
                        solo
                      />
                    </template>
                    <v-date-picker
                      v-model="filter.joinDate"
                      @input="joinDateMenu = false"
                      scrollable
                      no-title
                    />
                  </v-menu>
                </v-col>

                <v-col sm="4" cols="12">
                  <search-programs-input
                    v-model="program"
                    @input="changeProgram"
                    label="Programs"
                    placeholder="Search for Programs and Challenge"
                    return-object
                    clearable
                    hide-selected
                    outlined
                    flat
                  />
                </v-col>

                <v-col sm="2" cols="12">
                  <v-menu
                    v-model="startsAtDateMenu"
                    :close-on-content-click="false"
                    min-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="filter.startsAt"
                        v-on="on"
                        label="Starts At"
                        clearable
                        hide-details
                        outlined
                        readonly
                        flat
                        solo
                      />
                    </template>
                    <v-date-picker
                      v-model="filter.startsAt"
                      @input="startsAtDateMenu = false"
                      scrollable
                      no-title
                    />
                  </v-menu>
                </v-col>

                <v-col sm="2" cols="12">
                  <v-menu
                    v-model="endsAtDateMenu"
                    :close-on-content-click="false"
                    min-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="filter.endsAt"
                        v-on="on"
                        label="Ends At"
                        clearable
                        hide-details
                        outlined
                        readonly
                        flat
                        solo
                      />
                    </template>
                    <v-date-picker
                      v-model="filter.endsAt"
                      @input="endsAtDateMenu = false"
                      scrollable
                      no-title
                    />
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                    :items="purchaseTypes"
                    v-model="filter.purchaseType"
                    label="Purchase Type"
                    item-text="name"
                    item-value="key"
                    hide-details
                    clearable
                    outlined
                    solo
                    flat
                  />
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                    :items="[
                      { value: true, text: 'Has Selfie' },
                      { value: false, text: 'No Selfie' },
                    ]"
                    v-model="filter.hasSelfie"
                    label="Selfie"
                    hide-details
                    clearable
                    outlined
                    solo
                    flat
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    :items="[
                      { value: true, text: 'Has Membership' },
                      { value: false, text: 'No Membership' },
                    ]"
                    v-model="filter.membership"
                    label="Membership"
                    hide-details
                    clearable
                    outlined
                    solo
                    flat
                  />
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    label="Social Media Consent"
                    v-model="filter.socialMediaConsent"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <hr />

            <v-card-actions>
              <v-spacer />
              <v-btn text @click="openFilter = false">
                Cancel
              </v-btn>

              <v-btn color="warning" text @click="resetFilters">
                Reset
              </v-btn>

              <v-btn color="primary" text @click="applyFilters">
                Apply Filters
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12">
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </v-col>
      </v-row>
    </div>

    <scroll-top bottom fixed right dark fab />
  </div>
</template>

<script>
import MainAppBar from '@/layouts/shared/MainAppBar'
import { mdiDownload, mdiTune } from '@mdi/js'
import ScrollTop from '@/components/elements/ScrollTop'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { debounce } from 'lodash'
import SearchProgramsInput from '@/components/elements/programs/SearchProgramsInput'

export default {
  name: 'UsersListsPage',

  components: {
    MainAppBar,
    ScrollTop,
    SearchProgramsInput,
  },

  data() {
    return {
      loading: false,
      page: 1,
      searchKey: '',
      openFilter: false,

      joinDateMenu: null,
      program: null,
      startsAtDateMenu: false,
      endsAtDateMenu: false,

      icons: {
        export: mdiDownload,
        filter: mdiTune,
      },
    }
  },

  created() {
    this.ifHasSearch()
  },

  computed: {
    ...mapGetters({
      workoutExtraTypes: 'workouts/getWorkoutExtraTypes',
    }),

    ...mapState({
      filter: (state) => state.users.filter,
      purchaseTypes: (state) => state.users.extra.types.purchaseTypes,
    }),

    hasFilters() {
      return (
        !!this.filter.joinDate ||
        !!this.filter.programId ||
        !!this.filter.startsAt ||
        !!this.filter.endsAt ||
        !!this.filter.purchaseType ||
        typeof this.filter.socialMediaConsent === 'boolean' ||
        typeof this.filter.hasSelfie === 'boolean' ||
        typeof this.filter.membership === 'boolean'
      )
    },
  },

  methods: {
    ...mapActions({
      getUsers: 'users/getUsers',
    }),

    ...mapMutations({
      clearList: 'users/clearUserList',
      setFilter: 'users/setFilter',
      resetFilter: 'users/resetFilter',
    }),

    exportUsers() {
      window.open(
        this.$env.apiUrl +
          '/users/export?token=' +
          this.$store.state.auth.token.access_token,
        '_blank'
      )
    },

    ifHasSearch() {
      if (this.searchKey) {
        this.searchChange(this.searchKey)
      }
    },

    searchChange(key) {
      this.setFilter({
        search: key,
        type: this.$route.meta.type,
      })
      this.searchUsers()
    },

    changeProgram() {
      if (!this.program) return

      let { id } = this.program
      this.setFilter({
        programId: id,
        type: this.$route.meta.type,
      })
      this.searchUsers()
    },

    searchUsers: debounce(function() {
      this.clearList()
      this.fetchUsers()
    }, 600),

    async fetchUsers(page = 1) {
      if (this.loading) return

      this.loading = true

      let search = this.searchKey
      // eslint-disable-next-line camelcase
      let params = { ...this.filter, page, search }

      params.search = this.searchKey

      await this.getUsers(params)

      this.loading = false
    },

    applyFilters() {
      if (!this.hasFilters) return
      this.searchUsers()
    },

    resetFilters() {
      if (!this.hasFilters) return

      this.program = null
      this.resetFilter()
      this.searchUsers()
    },
  },

  watch: {
    $route(to, from) {
      this.searchKey = null

      this.setFilter({
        search: this.searchKey,
        type: this.$route.meta.type,
      })

      this.ifHasSearch()
    },
  },
}
</script>
